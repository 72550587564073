import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from '@material-ui/core/Button';


function handleLogout(instance) {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = () => {
    const { instance } = useMsal();

    return (
       
            <Button variant="contained" color="secondary" align="right" onClick={() => handleLogout(instance)}>Ausloggen</Button>

    );
}



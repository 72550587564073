import React from 'react';
import PropTypes from 'prop-types';
//Material-UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useMsal } from "@azure/msal-react";
import { useAccount } from "@azure/msal-react";


const styles = theme => ({
 bubbles:{

  display: 'contents'

 },
  bubble: {
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '75px',
    height: '75px',
    margin: '10px 0',
    backgroundColor: '#e29979',
    order: 3,
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#e29979',
      margin: '0',
      order: 1,
    },
    "&:last-child": {
      margin: '10px',
      order: 3,
      [theme.breakpoints.up('md')]: {
        margin: '0',
      },
    }
  },
  typography: {
    color: 'white',
    lineHeight: '1em',
  }
});

function UserBubble(props) {

  const { accounts } = useMsal();
  const account = useAccount(accounts?.[0] || {});

  console.log(account)

  const { classes } = props;
  return(
   
    <div className={classes.bubbles}>
      <div className={classes.bubble}>
        <Typography className={classes.typography} variant="h5">{ account.idTokenClaims.extension_Groesse }</Typography>
        <Typography className={classes.typography} variant="body2">cm</Typography>
      </div>
      <div className={classes.bubble}>
          <Typography className={classes.typography} variant="h5">{ account.idTokenClaims.extension_Gewicht }</Typography>
          <Typography className={classes.typography} variant="body2">kg</Typography>
        </div>
    </div>
  )
}

UserBubble.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserBubble);


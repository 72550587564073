import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import configureStore from './store/store';
import * as serviceWorker from './serviceWorker';
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './auth/authConfig';
import { MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

// create PublicClientApplication instance
export const publicClientApplication = new PublicClientApplication(msalConfig);

    ReactDOM.render(
       
        <Provider store={configureStore()}>
        <React.StrictMode>           
        <MsalProvider instance={publicClientApplication}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <App />
        </MsalAuthenticationTemplate>   
        </MsalProvider>
        </React.StrictMode>,
        </Provider>,
        document.getElementById('root')
   
    );

    
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


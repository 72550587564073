export const b2cPolicies = {
  names: {
      signUpSignIn: "B2C_1_login",
      editProfile: "B2C_1_edit_profile"
  },
  authorities: {
      signUpSignIn: {
          authority: "https://ausgewogenveganapp.b2clogin.com/ausgewogenveganapp.onmicrosoft.com/b2c_1_login",
      },
      editProfile: {
          authority: "https://ausgewogenveganapp.b2clogin.com/ausgewogenveganapp.onmicrosoft.com/B2C_1_edit_profile"
      }
  },
  authorityDomain: "ausgewogenveganapp.b2clogin.com"
}


// Configuration object constructed.
export const msalConfig = {
  auth: {
      clientId: 'b4f2209d-a18c-45ef-ba44-98295470f482',
      authority: b2cPolicies.authorities.signUpSignIn.authority,       
      knownAuthorities: [b2cPolicies.authorityDomain],
      redirectUri: "https://app.ausgewogen-vegan.de",
  },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};


 // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["openid", 'offline_access'],
   extraScopesToConsent:['https://AusgewogenVeganApp.onmicrosoft.com/b4f2209d-a18c-45ef-ba44-98295470f482/data.read']

  };


  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };




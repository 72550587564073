import { publicClientApplication } from "../index"


const nutritionInstantURL = "https://api-ausgewogenvegan.azurewebsites.net/api/food/";
const nutritionDetailGeneric = "https://api-ausgewogenvegan.azurewebsites.net/api/food/id/";

function errorHandeling(resJSON){
  console.log("ERROR: API");
  console.log(resJSON.message);
}

//Sets the metadata (old function)
export const setData = (payload) => dispatch => {
  dispatch({
    type: 'SET_DATA',
    payload: payload
  })
}

//Swap dates using the DateNavigation component
export const changeDate = (payload) => dispatch => {
  dispatch({
    type: 'CHANGE_DATE',
    payload: payload
  })
}

//Toggle action for focusing/unfocusing search bar
export const setSearchFocus = (payload) => dispatch => {
  dispatch({
    type: 'SEARCHBAR_FOCUS',
    payload: payload
  })
}

//Reset parameters when search cancel
export const setSearchDefocus = (payload) => dispatch => {
  dispatch({
    type:'SEARCHBAR_DEFOCUS',
    payload: payload
  })
}

//Retrieve a quick list of data to populate the autofill, based on search string
export const quickSearchFoods = (payload) => dispatch => {

    const account = publicClientApplication.getAllAccounts()[0];
    const accessTokenRequest = {
        scopes: ["b4f2209d-a18c-45ef-ba44-98295470f482"],
        account: account
    }
        
    // Use the same publicClientApplication instance provided to MsalProvider
    publicClientApplication.acquireTokenSilent(accessTokenRequest).then(function(accessTokenResponse) {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;

    //When empty, set no search items
    if (payload === ''){
      dispatch({
        type: 'SET_QUICK_SEARCH_DATA',
        payload: { 'common': []}
        //payload: { 'common': [], 'branded': [] }
      });
    }
    //Fetch
    else {
      console.log(accessToken);
      let configHeaders = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      };
      fetch(nutritionInstantURL + payload, {   
        method: 'GET',
        headers: configHeaders
      })
      .then((res) => {
        console.log("Result: quicksearch");
        console.log(res);
        return res.json();
      })
      .then((myJSON) => {
        
        if (myJSON.hasOwnProperty('message')){
        
          errorHandeling(myJSON);
        }
        else {
          
          dispatch({
            type: 'SET_QUICK_SEARCH_DATA',
            payload: myJSON
          });
        }
      }).catch((e) => {
        console.log("ERROR: occured when fetching." + e);
      });
    }

  }).catch(function (error) {
    //Acquire token silent failure
    console.log(error);
});
  }



  

export const setInspectFocus = (payload) => dispatch => {
  dispatch({
    type: 'SET_INSPECT_FOCUS',
    payload: payload
  });
}


//Get data from selected food using the nutrition data url
export const setInspectFood = (payload) => dispatch => {

  const account = publicClientApplication.getAllAccounts()[0];
  const accessTokenRequest = {
      scopes: ["b4f2209d-a18c-45ef-ba44-98295470f482"],
      account: account
  }

    // Use the same publicClientApplication instance provided to MsalProvider
    publicClientApplication.acquireTokenSilent(accessTokenRequest).then(function(accessTokenResponse) {
      // Acquire token silent success
      let accessToken = accessTokenResponse.accessToken;
 
  let configHeaders = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + accessToken
  };

  //Non-Branded
  if (payload.hasOwnProperty('id')) {
    //let configBody = { "query": payload.fields.Title };
    fetch(nutritionDetailGeneric + payload.id, {
      method: 'GET',
      headers: configHeaders
    })
    .then((res) => {
      console.log("Result: inspect");
      console.log(res);
      return res.json();
    })
    .then((myJSON) => {
      if (myJSON.hasOwnProperty('message')){
        errorHandeling(myJSON);
      }
      else {
        console.log("else")
        console.log(myJSON)
        dispatch({
          type: 'SET_INSPECT_FOOD',
          payload: myJSON.food
        });
      }
    });
  }
  //Clean fields (for closing app)
  else {
    dispatch({
      type: 'SET_INSPECT_FOOD',
      payload: {}
    });
  }

}).catch(function (error) {
  //Acquire token silent failure
  console.log(error);
});
}

//Add food to list of Todays consumption
export const appendFood = (food, servings, meal) => dispatch => {
  


  const account = publicClientApplication.getAllAccounts()[0];
  const accessTokenRequest = {
      scopes: ["b4f2209d-a18c-45ef-ba44-98295470f482"],
      account: account
  }
      
  // Use the same publicClientApplication instance provided to MsalProvider
  publicClientApplication.acquireTokenSilent(accessTokenRequest).then(function(accessTokenResponse) {
      // Acquire token silent success
      let accessToken = accessTokenResponse.accessToken;

      let configHeaders = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken      

      };

      let compiledFood = {
        "food_name": food.fields.Title,
        "serving_unit": "x 100 g/ml",
        "serving_weight_grams": "100",
        "serving_qty": "food.serving_qty",
        "nf_calories": food.fields.field_4,
        "serving_size" : Number(servings),
        "meal_type": meal,
        "thumb": "/favicon/android-chrome-192x192.png"
      }    

        fetch(nutritionInstantURL, {
          method: 'POST',
          headers: configHeaders,
          body: JSON.stringify({
            User: account.name,
            UserId: account.homeAccountId,
            Date: '2022-05-1T00:00:00Z',
            Category: meal,
            FoodLookupId: food.id,
            Portions: Number(servings)
          })
        })
    

  //Add branded if exists
 // if (food.nix_item_id) {
    //compiledFood["nix_item_id"] = food.nix_item_id
  //}
  dispatch({
    type: 'ADD_FOOD',
    payload: compiledFood
  });

}).catch(function (error) {
  //Acquire token silent failure
  console.log(error);
});

}

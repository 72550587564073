import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest, b2cPolicies } from "../../auth/authConfig";
import Button from '@material-ui/core/Button';

/* eslint-disable no-unused-vars */

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

/* eslint-enable no-unused-vars */

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const EditProfile = () => {
    const { instance } = useMsal();
    
    return (

        <>

        <Button variant="outlined" color="secondary" onClick={() => instance.loginRedirect(b2cPolicies.authorities.editProfile)} >Profil editieren</Button>

        </>
    );
}

import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";

import { SignOutButton } from "../../Login/SignOutButton";
import styled from 'styled-components';

/**
 * Renders the navbar component with a sign-out button if a user is not authenticated
 */

 const StyledDivContainer = styled.div`
 margin-top: 100px;
 margin-left: auto;
 margin-right: auto;
`


export const Login = (props) => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <>


            <StyledDivContainer>    
           
            { isAuthenticated ? <SignOutButton /> : "" }
            {props.children}

            </StyledDivContainer> 
        </>
    );
};